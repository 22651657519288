
[data-v-77fe28c7] .ivu-input-number {
  width: 100%;
}
.widget-klass-subjects[data-v-77fe28c7] {
  padding: 0 24px;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #FFF;
  min-height: 500px;
}
.widget-klass-subjects .content[data-v-77fe28c7] {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 16px 0;
}
.widget-klass-subjects .content .input-periods[data-v-77fe28c7] {
  width: 100%;
}
