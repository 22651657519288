.wg-modal .ivu-alert {
  border-radius: 0;
  border-left-color: transparent;
  border-right-color: transparent;
  margin-bottom: 0 !important;
  margin-top: -1px !important;
}
.wg-modal .ivu-alert.ivu-alert-with-icon {
  padding: 8px 38px;
}
.wg-modal .ivu-modal-body {
  padding: 0;
}
.wg-modal .ivu-modal-body .modal-container {
  padding: 32px 32px 8px 32px;
}
.wg-modal .ivu-modal-body .small-container {
  width: 384px;
}
.wg-modal .ivu-modal-body .middle-container {
  width: 450px;
}
.wg-modal .ivu-modal-body .auto-container {
  width: auto;
}
.wg-modal .ivu-modal-body .scroll-modal-container {
  max-height: 450px;
  overflow-y: scroll;
  width: 100%;
}
.wg-modal .ivu-select .ivu-select-dropdown {
  max-width: 300px;
  overflow-x: hidden;
}
.wg-modal .ivu-select .ivu-select-dropdown .ivu-select-dropdown-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 0;
}
.wg-modal .ivu-select .ivu-select-dropdown .ivu-select-dropdown-list li {
  overflow: hidden;
  text-overflow: ellipsis;
}
