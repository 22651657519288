.status-circle[data-v-672acefd] {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}
.PUBLISHED[data-v-672acefd],
.GRADUATED[data-v-672acefd],
.ISSUED[data-v-672acefd],
.OPENED[data-v-672acefd],
.APPROVED[data-v-672acefd],
.PAY_SUCCESS[data-v-672acefd],
.YES[data-v-672acefd],
.ENABLED[data-v-672acefd],
.IN_PROGRESS[data-v-672acefd],
.ADD[data-v-672acefd],
.SUCCESS[data-v-672acefd] {
  background-color: #6fd148;
}
.UNPUBLISHED[data-v-672acefd],
.NOT_STARTED[data-v-672acefd],
.UN_GRADUATED[data-v-672acefd],
.CANCEL_RESTUDY[data-v-672acefd],
.UNISSUED[data-v-672acefd],
.NO[data-v-672acefd],
.DRAFT[data-v-672acefd],
.UN_OPEN[data-v-672acefd] {
  background-color: #a4b5cd;
}
.PROCESSING[data-v-672acefd],
.DELAY_GRADUATED[data-v-672acefd],
.REFUND_SUCCESS[data-v-672acefd],
.PENDING_RESTUDY[data-v-672acefd],
.RESTUDY[data-v-672acefd],
.CHANGE[data-v-672acefd] {
  background-color: #f5a623;
}
.CANCELLED[data-v-672acefd],
.ENDED[data-v-672acefd],
.NON_COMPLETION[data-v-672acefd],
.REJECTED[data-v-672acefd],
.REMOVE[data-v-672acefd],
.DISABLED[data-v-672acefd],
.FAILURE[data-v-672acefd] {
  background-color: #f0485f;
}
.PENDING[data-v-672acefd],
.PAY_PENDING[data-v-672acefd] {
  background-color: #4892f6;
}
.REFUND_PENDING[data-v-672acefd] {
  background-color: #00bbbd;
}
