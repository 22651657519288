.check-rule-form[data-v-7b3e6316] {
  padding: 24px !important;
  color: #262833 !important;
  background: #f9f9f9 !important;
}
.check-rule-form[data-v-7b3e6316] .popper-inline-box {
  background-color: #eeeeee;
  padding: 16px 12px;
  color: #262833;
}
.check-rule-form[data-v-7b3e6316] .popper-inline-box .top-left:before {
  border-color: transparent;
  border-bottom-color: #eeeeee;
}
.check-rule-form[data-v-7b3e6316] .popper-inline-box .top-left:after {
  top: 1px;
  border-color: transparent;
  border-bottom-color: #eeeeee;
}
.check-rule-form .sub-rules[data-v-7b3e6316] {
  margin: 10px 25px;
  min-width: 270px;
}
.check-rule-form .sub-rules .online-video-sub-rule[data-v-7b3e6316] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}
.check-rule-form .sub-rules .online-video-sub-rule .progress[data-v-7b3e6316] {
  width: 80px;
  height: 24px;
  margin: 0 6px;
}
.check-rule-form .sub-rules .online-video-sub-rule .remove-rule[data-v-7b3e6316] {
  margin-left: 7px;
  color: #c5c8ce;
}
.check-rule-form[data-v-7b3e6316] .ivu-checkbox + span {
  margin-left: 4px;
}
.check-rule-form .tips[data-v-7b3e6316] {
  color: #737373;
  font-size: 12px;
  font-weight: 400;
}
