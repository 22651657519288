.check-box-group[data-v-c928fb4a] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 20px;
}
.check-box-group .check-box-item[data-v-c928fb4a] {
  margin-top: 16px;
}
