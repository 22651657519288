.portal-footer[data-v-6f167383] {
  padding: 0;
}
.common-content[data-v-6f167383],
.default-content[data-v-6f167383],
.portal-content[data-v-6f167383] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
}
.default-content[data-v-6f167383] {
  color: #999999;
}
.portal-content[data-v-6f167383] {
  padding: 7px 0;
  background-color: #3a435d;
  color: #a9b0c0;
}
