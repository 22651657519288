.text-box[data-v-5b11486b] .ivu-input {
  border-radius: 4px 0 0 4px;
}
.captcha-box[data-v-5b11486b] {
  border: 1px solid #dcdee2;
  border-left: 0;
  border-radius: 0 4px 4px 0;
  height: 32px;
  line-height: 32px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.captcha-box .captcha[data-v-5b11486b] {
  border: 0;
  height: 28px;
  vertical-align: middle;
}
