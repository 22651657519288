.wg-confirm-modal .icon-box[data-v-03da5038] {
  text-align: center;
  font-size: 40px;
}
.wg-confirm-modal .confirm[data-v-03da5038] {
  color: #00BBBD;
}
.wg-confirm-modal .warning[data-v-03da5038] {
  color: #ff9900;
}
.wg-confirm-modal .message-box[data-v-03da5038] {
  text-align: center;
  padding: 16px;
}
.wg-confirm-modal .action-box[data-v-03da5038] {
  text-align: center;
}
.wg-confirm-modal .more-content[data-v-03da5038] {
  margin: 0;
  background-color: #f9f9f9;
  font-size: 12px;
  color: #737373;
  line-height: 1.67;
  padding: 8px;
  white-space: pre-wrap;
}
