.widget-portal-card[data-v-3df3981c] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 16px 24px;
  background-color: #FFF;
  border-radius: 3px;
}
.widget-portal-card .card-title[data-v-3df3981c] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}
.widget-portal-card .card-title .card-title-line[data-v-3df3981c] {
  width: 2px;
  height: 16px;
  background-color: #00bbbd;
  border-radius: 1.2px;
}
.widget-portal-card .card-title .card-title[data-v-3df3981c] {
  margin-left: 8px;
}
.widget-portal-card .card-title .card-desc[data-v-3df3981c] {
  font-size: 13px;
  color: #737373;
}
.widget-portal-card .card-content[data-v-3df3981c] {
  padding: 32px 0 16px;
}
