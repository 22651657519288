.klass-summaries-card[data-v-5159b884] {
  border: solid 1px #e8e8e8;
  border-radius: 3px !important;
}
.klass-summaries-card .klass-name[data-v-5159b884] {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}
.klass-summaries-card .merged-content[data-v-5159b884] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
}
.klass-summaries-card .merged-content .plan-name[data-v-5159b884] {
  -ms-flex: 1;
  flex: 1;
}
.klass-summaries-card .merged-content .merged[data-v-5159b884] {
  padding: 4px 6px;
  font-size: 12px;
  color: #00bbbd;
  background-color: #d6f4f4;
  border-radius: 2px;
  margin-right: 6px;
}
.klass-summaries-card[data-v-5159b884] .base-info-item {
  padding: 8px 10px !important;
}
