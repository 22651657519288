.dropdown-list[data-v-f78354c6] {
  line-height: normal;
}
.dropdown-list .nodata-message-box[data-v-f78354c6] {
  width: 240px;
  min-height: 300px;
}
.dropdown-list .message-list[data-v-f78354c6] {
  width: 240px;
  max-height: 500px;
  overflow-y: scroll;
}
.dropdown-list .message-list .message-box[data-v-f78354c6] {
  text-align: left;
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
}
.dropdown-list .message-list .message-box[data-v-f78354c6]:last-child {
  border-bottom: 1px solid transparent;
}
.dropdown-list .message-list .message-box[data-v-f78354c6]:hover {
  background-color: #f9f9f9;
}
.dropdown-list .message-list .message-box .message-content[data-v-f78354c6] {
  font-size: 13px;
  color: #333333;
}
.dropdown-list .message-list .message-box .message-read[data-v-f78354c6] {
  color: #737373;
}
.dropdown-list .message-list .message-box .message-footer[data-v-f78354c6] {
  margin-top: 6px;
  font-size: 12px;
  color: #737373;
}
.dropdown-list .message-action[data-v-f78354c6] {
  border-top: 1px solid #e8e8e8;
  padding: 16px;
  color: #00BBBD;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
}
.notice-badge[data-v-f78354c6] {
  /*margin-right: 18px;*/
}
[data-v-f78354c6] .ivu-badge-count {
  font-family: normal;
  line-height: 18px;
  vertical-align: middle;
  position: absolute;
  top: 6px;
  left: 8px;
  height: auto;
  border-radius: 10px;
  min-width: 18px;
  background: #f0485f;
  color: #fff;
  text-align: center;
  padding: 0 5px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
  border: 0;
  right: unset;
  box-shadow: unset;
  transform: unset;
  transform-origin: unset;
}
