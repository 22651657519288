.search-bar-item[data-v-f0c472ac] {
  margin-top: 10px;
  padding-left: 12px;
  padding-right: 12px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  min-width: -webkit-min-content;
  min-width: min-content;
}
.search-bar-item .label[data-v-f0c472ac] {
  min-width: 60px;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: auto;
  margin-right: 10px;
  text-align: right;
}
.search-bar-item .content[data-v-f0c472ac] {
  -ms-flex: 1;
  flex: 1;
  width: 0;
}
