.attachment-item[data-v-60aa6f5e] {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.attachment-item .attachment-title[data-v-60aa6f5e] {
  border-radius: 3px 3px 0 0;
  border: solid 1px #e8e8e8;
  background-color: #f9f9f9;
  margin-bottom: 0;
  padding: 8px;
  color: #737373;
}
.attachment-item .attachment-content[data-v-60aa6f5e] {
  position: relative;
}
.attachment-item .attachment-content .img-box[data-v-60aa6f5e] {
  width: 100%;
  height: 147px;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #dcdee1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.attachment-item .attachment-content .img-box .default-img[data-v-60aa6f5e] {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 110px;
  object-fit: contain;
}
.attachment-item .attachment-content .img-box img[data-v-60aa6f5e] {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.attachment-item .attachment-content .item-cover[data-v-60aa6f5e] {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  margin-right: 4px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.attachment-item .attachment-content .item-cover i[data-v-60aa6f5e] {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px;
}
.attachment-item .attachment-content:hover .item-cover[data-v-60aa6f5e] {
  display: -ms-flexbox;
  display: flex;
}
