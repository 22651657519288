.ivu-alert-with-desc[data-v-6a66f12c] {
  padding-top: 8px;
  padding-bottom: 8px;
}
.alert-group[data-v-6a66f12c] {
  margin-bottom: 16px;
}
.alert-group .label[data-v-6a66f12c] {
  min-width: 60px;
}
.alert-group .label[data-v-6a66f12c]:after {
  content: ":\00A0";
}
.alert-group .content[data-v-6a66f12c] {
  padding-left: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.alert-group .icon[data-v-6a66f12c] {
  width: 18px;
  height: 18px;
}
.alert-group .title[data-v-6a66f12c] {
  font-size: 13px;
}
.alert-group .desc[data-v-6a66f12c] {
  margin-left: 12px;
  color: #737373;
}
.alert-group .desc .pre[data-v-6a66f12c] {
  margin: 0;
  padding: 1px 0;
  font-size: 12px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.alert-group .info[data-v-6a66f12c] {
  color: #2d8cf0;
}
.alert-group .success[data-v-6a66f12c] {
  color: #19be6b;
}
.alert-group .warning[data-v-6a66f12c] {
  color: #ff9900;
}
.alert-group .error[data-v-6a66f12c] {
  color: #ed4014;
}
