.radio-item[data-v-3cc50b6b] {
  padding: 0 25px;
}
[data-v-3cc50b6b] .ivu-radio-wrapper-checked {
  background: #00BBBD;
  color: #ffffff;
}
[data-v-3cc50b6b] .ivu-radio-wrapper-checked:hover {
  background: #00BBBD;
  color: #ffffff;
}
[data-v-3cc50b6b] .ivu-radio-focus {
  box-shadow: none !important;
}
[data-v-3cc50b6b] .ivu-radio-wrapper:after {
  width: 0;
}
