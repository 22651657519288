.image-preview-modal[data-v-7501c07e] .ivu-modal {
  height: 368px;
  top: calc((100vh - 368px) / 2);
}
.image-preview-modal[data-v-7501c07e] .ivu-modal-close {
  right: 16px;
  top: 16px;
}
.image-preview-modal .carousel-item[data-v-7501c07e] {
  height: 336px !important;
}
.image-preview-modal .img[data-v-7501c07e] {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 1px solid #e8e8e8;
}
.image-preview-modal-vertical[data-v-7501c07e] .ivu-modal {
  height: 478px;
  top: calc((100vh - 478px) / 2);
}
.image-preview-modal-vertical[data-v-7501c07e] .ivu-modal-close {
  right: 16px;
  top: 16px;
}
.image-preview-modal-vertical .carousel-item[data-v-7501c07e] {
  height: 446px !important;
}
.image-preview-modal-vertical .img[data-v-7501c07e] {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 1px solid #e8e8e8;
}
