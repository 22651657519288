.widget-image-scroll-list[data-v-590a689f] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}
.widget-image-scroll-list .scroll-content[data-v-590a689f] {
  margin: 0 16px;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.widget-image-scroll-list .scroll-content img[data-v-590a689f] {
  height: 151px;
  width: 100%;
  object-fit: cover;
}
