.chat_entry {
  box-shadow: none !important;
  background: none !important;
  margin-bottom: 120px !important;
  right: 28px !important;
  opacity: 0.7 !important;
}
.chat_entry #lim_mini_icon {
  background: url(/static/images/live800-fb68fbec.svg) no-repeat center rgba(29, 29, 29, 0.26);
  background-size: 60% 60%;
  margin: 0 2px 5px 0;
  padding: 8px;
  border-radius: 5px;
  width: 48px;
  height: 48px;
}
.chat_entry #lim_mini_icon:hover {
  background-color: #00bbbd;
}
