.vue-treeselect__portal-target {
  z-index: 9999 !important;
}
.tree-select /deep/ .vue-treeselect__control {
  height: 32px;
  font-size: 13px;
  border-color: #dcdee2;
  border-radius: 4px;
}
.tree-select /deep/ .vue-treeselect__control .vue-treeselect__single-value {
  line-height: 30px;
  color: #333333;
}
.tree-select /deep/ .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__placeholder {
  line-height: 30px;
}
.tree-select /deep/ .vue-treeselect__control .vue-treeselect__value-container .vue-treeselect__multi-value {
  line-height: 30px;
}
.tree-select /deep/ .vue-treeselect__control:hover {
  border-color: #00BBBD !important;
}
/deep/ .vue-treeselect__menu {
  border: 0;
  border-radius: 4px;
  box-shadow: 0 1px 6px rgb(0 0 0%) !important;
}
.vue-treeselect--open-below /deep/ .vue-treeselect__menu {
  top: 6px;
  margin-top: 0;
}
.vue-treeselect--open-above /deep/ .vue-treeselect__menu {
  bottom: 6px;
  margin-bottom: 0;
}
/deep/ .vue-treeselect__option--selected {
  background: #e4fafa;
}
/deep/ .vue-treeselect__option--selected:hover {
  background: #e4fafa;
}
.tree-select-focused /deep/ .vue-treeselect__control {
  border-color: #33c9ca;
  box-shadow: 0 0 0 2px rgba(0, 187, 189, 0.2);
}
.tree-select-not-focus /deep/ .vue-treeselect__control {
  box-shadow: unset;
}
/deep/ .vue-treeselect__input-container {
  display: flex;
  align-items: center;
}
.vue-treeselect--append-to-body .vue-treeselect__menu {
  margin: 5px 0;
  border: 1px solid #eee;
  border-radius: 4px;
  line-height: 30px !important;
}
