.text-ellipsis,
.plan-card .plan-content .plan-name-certificate .name,
.plan-card .plan-content .detail-content {
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.status-position,
.plan-card .cover .NOT_STARTED,
.plan-card .cover .PROCESSING,
.plan-card .cover .ENDED {
  position: absolute;
  width: 56px;
  height: 30px;
  top: 14px;
  left: -4px;
}
.plan-card {
  margin: 24px 8px 0 8px;
  cursor: pointer;
  width: 250px;
  border-radius: 3px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.plan-card:hover {
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.24);
}
.plan-card .cover {
  height: 141px;
  background-color: #dcdee1;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  position: relative;
}
.plan-card .cover .NOT_STARTED {
  background: url(/static/images/not_started-c52cc289.png) no-repeat center;
  background-size: contain;
}
.plan-card .cover .PROCESSING {
  background: url(/static/images/processing-3baee24f.png) no-repeat center;
  background-size: contain;
}
.plan-card .cover .ENDED {
  background: url(/static/images/ended-800424ef.png) no-repeat center;
  background-size: contain;
  width: 68px;
}
.plan-card .cover img {
  height: 141px;
  min-width: 100%;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  object-fit: cover;
}
.plan-card .cover .mask {
  position: absolute;
  min-width: 100%;
  height: 56px;
  background-image: linear-gradient(to bottom, rgba(225, 225, 225, 0), rgba(0, 0, 0, 0.3), #000000);
  bottom: 0;
  left: 0;
}
.plan-card .cover .org {
  position: absolute;
  bottom: 5px;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 14px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 10px;
}
.plan-card .cover .org span {
  margin-left: 6px;
  color: #ffffff;
}
.plan-card .plan-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin: 16px 16px 17px 16px;
  line-height: 1;
}
.plan-card .plan-content .plan-name-certificate {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
}
.plan-card .plan-content .plan-name-certificate .name {
  font-size: 16px;
  font-weight: 500;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.85);
}
.plan-card .plan-content .plan-name-certificate .certificate {
  padding: 3px 6px;
  height: 18px;
  font-size: 12px;
  letter-spacing: 0.4px;
  border-radius: 2px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e2f3fd;
  color: #48b3f6;
}
.plan-card .plan-content .plan-name-certificate .FORENSICS {
  color: #00bbbd;
  background-color: #d6f4f4;
}
.plan-card .plan-content .plan-name-certificate .NON_FORENSICS {
  color: #ff7800;
  background-color: #ffe9d6;
}
.plan-card .plan-content .plan-detail {
  display: -ms-flexbox;
  display: flex;
  margin-top: 12px;
  color: #737373;
  height: 14px;
}
.plan-card .plan-content .detail-label {
  margin-right: 14px;
}
.plan-card .plan-content .plan-count-fee {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.plan-card .plan-content .plan-count-fee .count {
  display: -ms-flexbox;
  display: flex;
}
.plan-card .plan-content .plan-count-fee .fee {
  font-size: 16px;
  font-weight: bold;
  color: #ff7800;
}
.plan-card .card-footer {
  line-height: 1;
  height: 40px;
  opacity: 0.7;
  background-color: #f5f7f9;
  padding: 11px 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}
.plan-card-after {
  content: "";
  width: 250px;
  height: 0;
}
