.container[data-v-5f03aa56] {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
}
.container .widget-affix[data-v-5f03aa56] {
  width: 100%;
}
.container .widget-affix .widget-affix-breadcrumb[data-v-5f03aa56] {
  height: 50px;
  background-color: #FFF;
  box-shadow: 2px 0 8px 0 rgba(0, 0, 0, 0.08);
}
.container .widget-affix .widget-affix-breadcrumb .breadcrumb-content[data-v-5f03aa56] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.container .widget-affix .widget-affix-breadcrumb .breadcrumb-content .content-left[data-v-5f03aa56] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}
.container .content[data-v-5f03aa56] {
  padding: 26px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}
