.tab-nav-item-wrapper[data-v-40985dd8] {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.002);
  padding: 6px 20px;
  border-radius: 4px 4px 0px 0px;
  border: solid 1px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border-bottom: 0px;
  height: 34px;
}
.tab-nav-item-wrapper.tab-nav-item-active[data-v-40985dd8] {
  border-color: #00BBBD;
  color: #00BBBD;
  background-color: #fff !important;
  height: 35px;
}
.tab-nav-item-wrapper[data-v-40985dd8]:hover {
  color: #00BBBD;
}
