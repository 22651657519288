.wg-success-unclosable-model .icon-box[data-v-a65258c8] {
  text-align: center;
  font-size: 40px;
}
.wg-success-unclosable-model .confirm[data-v-a65258c8] {
  color: #00BBBD;
}
.wg-success-unclosable-model .message-box[data-v-a65258c8] {
  text-align: center;
  padding: 16px;
}
.wg-success-unclosable-model .action-box[data-v-a65258c8] {
  text-align: center;
}
