.type-tag[data-v-2344958e] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.type-tag .type[data-v-2344958e] {
  padding: 4px 6px;
  height: 20px;
  font-size: 12px;
  letter-spacing: 0.4px;
  border-radius: 2px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e2f3fd;
  color: #48b3f6;
}
.type-tag .FORENSICS[data-v-2344958e],
.type-tag .ENROLLMENT_STUDENT[data-v-2344958e],
.type-tag .GRADUATION[data-v-2344958e] {
  color: #00bbbd;
  background-color: #d6f4f4;
}
.type-tag .NON_FORENSICS[data-v-2344958e],
.type-tag .RESTUDY_STUDENT[data-v-2344958e],
.type-tag .STUDENT[data-v-2344958e] {
  color: #ff7800;
  background-color: #ffe9d6;
}
