.registration-serve-info .info-label-large[data-v-12ae1ba6] {
  min-width: 100px;
}
.registration-serve-info .info-box[data-v-12ae1ba6] {
  margin-top: -12px;
  margin-bottom: -12px;
}
.registration-serve-info .info-box .info-item[data-v-12ae1ba6] {
  margin-top: 12px;
  margin-bottom: 12px;
}
