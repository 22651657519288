.enrollment-register-card[data-v-46ac3398] {
  border: solid 1px #e8e8e8;
  border-radius: 3px !important;
}
.enrollment-register-card .enrollment-register-info[data-v-46ac3398] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  border-right: 1px solid #e8e8e8;
  padding: 20px 0;
}
.enrollment-register-card .enrollment-register-info .cover-content[data-v-46ac3398] {
  position: relative;
}
.enrollment-register-card .enrollment-register-info .cover-content .enrollment-cover[data-v-46ac3398] {
  width: 240px;
  height: 136px;
  border-radius: 6px;
}
.enrollment-register-card .enrollment-register-info .cover-content .level[data-v-46ac3398] {
  position: absolute;
  left: 10px;
  top: 10px;
  padding: 2px 8px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #ffffff;
  font-size: 12px;
}
.enrollment-register-card .enrollment-register-info .enrollment-name-content[data-v-46ac3398] {
  padding: 14px 0;
  -ms-flex: auto;
  flex: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 0;
}
.enrollment-register-card .enrollment-register-info .enrollment-name-content .enrollment-name[data-v-46ac3398] {
  font-size: 16px;
  font-weight: 600;
}
.enrollment-register-card .enrollment-register-info .enrollment-name-content .plan-version[data-v-46ac3398] {
  margin-right: 20px;
}
.enrollment-register-card .register-fee[data-v-46ac3398] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: DINOT;
  font-size: 32px;
  color: #ff7800;
  font-weight: bold;
}
