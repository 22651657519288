.no-data[data-v-5f01a765] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
}
.no-data .no-data-img[data-v-5f01a765] {
  width: 146px;
  height: 100px;
  background: url(/static/images/nodata-994c84e5.png) no-repeat center;
  background-size: contain;
}
.no-data .no-data-tip[data-v-5f01a765] {
  margin-top: 8px;
  font-weight: 500;
  color: #999999;
}
