.container-flex[data-v-1b0e2ed2],
.container[data-v-1b0e2ed2],
.container .content[data-v-1b0e2ed2] {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.container[data-v-1b0e2ed2] {
  padding: 0 16px;
  /*TODO may use Row to replace*/
  width: 0;
}
.container .content-header[data-v-1b0e2ed2] {
  padding: 22px 0;
  border-bottom: #e8e8e8 solid 1px;
}
.container .content[data-v-1b0e2ed2] {
  padding: 16px 0;
}
