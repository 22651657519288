
[data-v-75c32ac4] .ivu-back-top-inner {
  opacity: 0.16;
  border-radius: 5px;
  background-color: #000000;
  height: 48px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
[data-v-75c32ac4] .ivu-back-top-inner:hover {
  opacity: 1;
  background-color: #00BBBD;
}
[data-v-75c32ac4] .ivu-back-top {
  display: block;
}
[data-v-75c32ac4] .ivu-scroll-container {
  width: 100%;
  height: calc(100vh - 150px) !important;
}
[data-v-75c32ac4] .ivu-scroll-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: auto;
  flex: auto;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
}
.back-top-point[data-v-75c32ac4] {
  position: relative;
  top: -100px;
}
.container[data-v-75c32ac4] {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
}
.container .widget-affix[data-v-75c32ac4] {
  width: 100%;
}
.container .widget-affix .widget-affix-breadcrumb[data-v-75c32ac4] {
  height: 50px;
  background-color: #FFF;
  box-shadow: 2px 0 8px 0 rgba(0, 0, 0, 0.08);
}
.container .widget-affix .widget-affix-breadcrumb .breadcrumb-content[data-v-75c32ac4] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.container .widget-affix .widget-affix-breadcrumb .breadcrumb-content .content-left[data-v-75c32ac4] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
}
.container .widget-affix .widget-affix-breadcrumb .breadcrumb-content .content-right[data-v-75c32ac4] {
  margin-right: 10px;
}
.container .scroll-box[data-v-75c32ac4] {
  width: 100%;
}
.container .content[data-v-75c32ac4] {
  padding: 16px 0 26px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 1080px;
}
.filter-container[data-v-75c32ac4] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 9px;
  background-color: #f9fafb;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #dedede;
}
.filter-container .filter[data-v-75c32ac4] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  border-bottom: 1px dashed #e8e8e8;
  padding: 15px;
  -ms-flex-align: center;
  align-items: center;
}
.filter-container .filter[data-v-75c32ac4]:last-child {
  border-bottom: none;
}
.filter-container .filter .filter-title[data-v-75c32ac4] {
  font-weight: 600;
  margin-right: 18px;
  font-size: 13px;
  color: #333333;
}
.filter-container .filter .filter-title[data-v-75c32ac4]:after {
  content: ":\00A0";
}
.filter-container .filter .filter-item[data-v-75c32ac4] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
}
.filter-container .filter .filter-item .item[data-v-75c32ac4] {
  margin-right: 24px;
  font-size: 14px;
  font-weight: 600;
  color: #737373;
}
.filter-container .filter .filter-item .item .item-click[data-v-75c32ac4] {
  color: #00BBBD;
}
.filter-container .filter .filter-item .item a[data-v-75c32ac4] {
  color: #737373;
}
.filter-container .filter .filter-item .item a[data-v-75c32ac4]:hover {
  color: #00BBBD;
}
.filter-container .filter .filter-item .item[data-v-75c32ac4]:after {
  background: #00BBBD;
}
.filter-container .filter .filter-item .item[data-v-75c32ac4]:hover:after {
  left: 0;
  width: 100%;
}
.filter-container .filter-multi-line[data-v-75c32ac4] {
  -ms-flex-align: normal;
  align-items: normal;
  padding: 15px 15px 0;
}
.filter-container .filter-multi-line .filter-title[data-v-75c32ac4] {
  -ms-flex: none;
  flex: none;
  padding-top: 1px;
}
.filter-container .filter-multi-line .filter-item .item[data-v-75c32ac4] {
  margin-bottom: 15px;
}
.plan-list[data-v-75c32ac4] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
