.user-info-card .info-label[data-v-5b163ad1] {
  min-width: 73px;
}
.user-info-card .info-box[data-v-5b163ad1] {
  margin-top: -12px;
  margin-bottom: -12px;
}
.user-info-card .info-box .info-item[data-v-5b163ad1] {
  margin-top: 12px;
  margin-bottom: 12px;
}
