.ivu-cell[data-v-26a80c90] {
  background-color: #f9f9f9;
}
.ivu-cell[data-v-26a80c90]:not(:first-child) {
  margin-top: 4px;
}
.ivu-cell[data-v-26a80c90]:hover {
  background-color: #eeeeee;
}
.ivu-cell .default[data-v-26a80c90] {
  background-color: transparent;
  border: none;
  color: #00BBBD;
}
.ivu-cell .ivu-btn-primary[data-v-26a80c90]:focus {
  box-shadow: none;
}
.no-subject[data-v-26a80c90] {
  height: 250px;
  background-color: #f9f9f9;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
}
.no-subject .no-data-img[data-v-26a80c90] {
  width: 146px;
  height: 100px;
  background: url(/static/images/nodata-994c84e5.png) no-repeat center;
  background-size: contain;
}
.no-subject .no-data-tip[data-v-26a80c90] {
  margin-top: 8px;
  font-weight: 500;
  color: #999999;
}
