.widget-nav-bar[data-v-3e78d13a] {
  background-color: #3a435d;
}
.widget-nav-bar .nav-bar[data-v-3e78d13a] {
  padding: 0 32px;
}
.widget-nav-bar .logo[data-v-3e78d13a] {
  height: 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: start;
  align-items: flex-start;
}
.widget-nav-bar .logo img[data-v-3e78d13a] {
  height: 34px;
  object-fit: contain;
}
