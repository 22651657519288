.popper-inline-box[data-v-50672dc2] {
  position: relative;
  border: solid 1px #e8e8e8;
  background-color: #f9f9f9;
  padding: 0 8px;
  border-radius: 4px;
  line-height: 1.5rem;
  color: #737373;
}
.popper-inline-box.arrow-at-top[data-v-50672dc2] {
  margin-top: 10px;
  padding: 8px;
}
.popper-inline-box .left-top[data-v-50672dc2] {
  left: -17px;
  top: 9px;
}
.popper-inline-box .left-top[data-v-50672dc2]:before {
  border-color: transparent;
  border-right-color: #e8e8e8;
}
.popper-inline-box .left-top[data-v-50672dc2]:after {
  left: 1px;
  border-color: transparent;
  border-right-color: #f9f9f9;
}
.popper-inline-box .top-left[data-v-50672dc2] {
  left: 9px;
  top: -17px;
}
.popper-inline-box .top-left[data-v-50672dc2]:before {
  border-color: transparent;
  border-bottom-color: #e8e8e8;
}
.popper-inline-box .top-left[data-v-50672dc2]:after {
  top: 1px;
  border-color: transparent;
  border-bottom-color: #f9f9f9;
}
.popper-inline-box .arrow[data-v-50672dc2] {
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
}
.popper-inline-box .arrow[data-v-50672dc2]:before,
.popper-inline-box .arrow[data-v-50672dc2]:after {
  content: "";
  position: absolute;
  display: block;
  border-width: 8px;
  border-style: solid;
}
