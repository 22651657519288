
[data-v-3b845a72] .ivu-tag {
  display: inline-block;
  float: left;
  width: 40px;
  height: 20px;
  line-height: 18px;
  margin: 0 6px 0 0;
  padding: 0 6px;
}
.level-one-tag[data-v-3b845a72] {
  padding-left: 16px;
}
.level-one[data-v-3b845a72] {
  border: 1px solid #ffcb80;
  background: #fff5e5;
}
.level-one[data-v-3b845a72] .ivu-tag-text {
  color: #f28500;
}
.level-two[data-v-3b845a72] {
  border: 1px solid #a3c8fb;
  background: #eff6ff;
}
.level-two[data-v-3b845a72] .ivu-tag-text {
  color: #4183dd;
}
.level-three[data-v-3b845a72] {
  border: 1px solid #b7e6e4;
  background: #e4fafa;
}
.level-three[data-v-3b845a72] .ivu-tag-text {
  color: #068894;
}
